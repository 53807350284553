<template>
  <div class="dataUpload padding-lr-20 padding-top-20" >
        <div class="column"  :style="{'margin-bottom':0}" >
          <el-tabs v-model="activeKey" >
            <el-tab-pane label="本地导入" name="local"></el-tab-pane>
<!--            <el-tab-pane label="数据源导入" name="dataSource"></el-tab-pane>-->
          </el-tabs>
          <el-row v-if="activeKey=='local'" :gutter="20" type="flex" :style="{'min-height':($root.$data.docHeight-189)+'px'}" >
            <el-col :span="6" >
              <div class="font-size-14 margin-bottom-10">导入数据</div>
              <div  class="flex-row align-items-start flex-wrap ">
                  <div class="uploadFileTitle color-sub" >上传文件：</div>
                  <el-upload ref="elUpload" style="margin-right: 10px"
                             action=""
                             drag
                             multiple :show-file-list="false"
                             accept=".xls,.XLS,.xlsx,.XLSX"
                             :http-request="httpRequest"
                  >
                    <el-button class="uploadBta" size="mini" >上传</el-button>
                  </el-upload>
                  <el-button @click="downloadDemo"  size="mini" >下载模板</el-button>
              </div>
              <el-divider></el-divider>
              <div class="margin-top-10 line-height-large">
                <div>上传文件说明</div>
                <div>
                  1.&nbsp;一次仅能上传一个.xlsx或.xls文件，且文件只有一个sheet表
                </div>
                <div >
                  2.&nbsp;sheet表的首行为数据名称，不能以下划线开头，可以用括号的形式标识数据的单位。为了便于您使用，数据名称尽量不要重复。
                </div>
                <div>
                  3.&nbsp;sheet表中必须至少包含两列，首列为日期列，总列数不得超过100列。
                </div>
                <div>
                  4.&nbsp;日期列中日期需要精确到日，且格式须为日期格式。同时不可以有重复日期。
                </div>
                <div class="margin-bottom-10">
                  5.&nbsp;数据列除了首行的名称外，其他请使用数字填充，小数点后最多保留5位数，无值的单元格为空，否则格式异常的数据列将无法导入。
                </div>
                <div>
                  温馨提示：卓创会保护您上传数据的安全，同时，您可以将数据脱敏后再上传，如通过数据统计加工，名称处理等方法。
                </div>
              </div>
              <el-divider></el-divider>
              <div>
                <div class="margin-bottom-10">上传文件样例</div>
                <basic-table :data="tableDataDemo" :columns="columnsDemo"></basic-table>
              </div>
            </el-col>
            <el-col :span="18">
              <upload-for-excel ref="uploadDrag"  ></upload-for-excel>
            </el-col>
          </el-row>
          <el-row v-else type="flex">
           此功能正在研发中。。。
          </el-row>
        </div>

  </div>
</template>

<script>
import BasicTable from "@/components/datamanage/basicTable";
import UploadForExcel from "@/components/datamanage/uploadForExcel";
export default {
  components: {
    UploadForExcel,
    BasicTable
  },
  name: "dataUpload",
  data() {//数据
    return {
      activeKey:"local",
      columnsDemo:[
        {prop:'date', label:'日期',},
        {prop:'row1', label:'某某指标(吨)',},
        {prop:'row2', label:'某某指标(率)',},
      ],
      tableDataDemo:[
          {date:'2022/07/03',row1:'1034',row2:'0.29'},
          {date:'2022/07/02',row1:'1158',row2:'0.25'},
          {date:'2022/07/01',row1:'1096',row2:'0.35'},
      ],
      file:'未上传',
    };
  },
  created() {//实例初始化之前的方法
  },
  mounted() {//实例挂载之前的方法
  },
  methods: {
    httpRequest(e){
      this.$refs.uploadDrag.httpRequest(e);
    },
    downloadDemo(){
      this.handleDownload();
    },
    handleDownload() {
      let checkList = [{ResourcesUrl:'https://img.sciimg.com/ppr/uploadDataModel.xlsx',ResourcesName:'上传数据模板.xlsx'}]
      checkList && checkList.length && checkList.forEach(item => {
          let a = document.createElement('a') // 创建a标签
          let e = document.createEvent('MouseEvents') // 创建鼠标事件对象
          e.initEvent('click', false, false) // 初始化事件对象
          a.href = item.ResourcesUrl // 设置下载地址
          a.download = item.ResourcesName // 设置下载文件名 此处可以修改为自己想要的下载文件名
          a.dispatchEvent(e)
      })
    },

  },

}
</script>
<style  lang="scss">
.dataUpload{
  .el-upload{
    width: 100%;
    height: 100%;
    .el-upload-dragger{
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  .el-button{
    padding: 5px 15px;
    font-size: 13px;
  }
}
/* 拖拽上传样式 ，自定义宽高*/
</style>
<style scoped lang="scss">
.uploadFileTitle{
  text-align: left;
}
.uploadBta{
  width:85px;
  padding: 5px;
  font-size: 13px;
  background:#004ea3;
  color: #ffffff;
  border-color: #004ea3;
}
.el-divider{
  margin: 10px 0;
}
</style>
