import { render, staticRenderFns } from "./DataUpload.vue?vue&type=template&id=66dca3fb&scoped=true&"
import script from "./DataUpload.vue?vue&type=script&lang=js&"
export * from "./DataUpload.vue?vue&type=script&lang=js&"
import style0 from "./DataUpload.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DataUpload.vue?vue&type=style&index=1&id=66dca3fb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66dca3fb",
  null
  
)

export default component.exports